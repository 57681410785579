import "./Centerbox.css"

function Centerbox({ children, ...props }) {
  return (
    <div {...props} className={`center-wrapper ${props.className || ""}`}>
      {children}
    </div>
  )
}
export default Centerbox
