"React strict mode"
import { useEffect, useState } from "react"
import "./App.css"
import { Routes, Route, Navigate } from "react-router-dom"
import Layout from "./pages/Layout"
import Dashboard from "./pages/Dashboard"
import Messages from "./pages/Messages"
import SendMessages from "./pages/SendMessages"
import Templates from "./pages/Templates"
import LoadingIndicator from "./components/LoadingIndicator"
import { getMessages, getTemplates } from "./libs/knoti-sdk"
import { loginWithPassword } from "./libs/auth-helper"

function before2Weeks() {
  const today = new Date()
  const todayBefore2Weeks = new Date(today.getTime() - 14 * 24 * 60 * 60 * 1000)
  const year = todayBefore2Weeks.getFullYear()
  const month = todayBefore2Weeks.getMonth() + 1
  const day = todayBefore2Weeks.getDate()
  return `${year}-${month}-${day}`
}

function App() {
  const [queuedMessages, setQueuedMessages] = useState([])
  const [pastMessages, setPastMessages] = useState([])
  const [templates, setTemplates] = useState([])
  const [isLoading, setLoading] = useState(true)
  const isSessionInLocal =
    JSON.parse(window.localStorage.getItem("lg")) || false
  const [isLogin, setLogin] = useState(isSessionInLocal)

  const onLoginHandler = async e => {
    e.preventDefault()

    const curForm = new FormData(e.currentTarget)
    const password = curForm.get("password")

    try {
      const res = await loginWithPassword(password)
      console.log("res", res)
      setLogin(true)
      alert("로그인 되었습니다.\n24시간 동안 유지됩니다.")
      window.localStorage.setItem("lg", "true")
    } catch (err) {
      console.log("err", err)
      alert("비밀번호가 틀립니다.")
    }
  }

  useEffect(() => {
    ;(async () => {
      const msg = await getMessages({
        start: before2Weeks()
      })
      const { quMsg, pstMsg } = msg.reduce(
        (acc, cur) => {
          if (cur?.list[0]?.msgid?.startsWith("Q")) {
            acc.quMsg.push(cur)
          } else {
            acc.pstMsg.push(cur)
          }
          return acc
        },
        { quMsg: [], pstMsg: [] }
      )

      const tmpls = await getTemplates()
      console.log("quMsg", quMsg)
      console.log("pstMsg", pstMsg)

      setQueuedMessages(quMsg)
      setPastMessages(pstMsg)
      setTemplates(tmpls)
      setLoading(false)
    })()
  }, [])
  return isLogin ? (
    <Layout queuedMessages={queuedMessages} templates={templates}>
      {isLoading ? (
        <div className="loading-indicator-wrapper">
          <LoadingIndicator />
        </div>
      ) : (
        <Routes>
          <Route
            path="dashboard"
            element={<Dashboard messages={queuedMessages} />}
          />
          <Route
            path="dashboard/messages"
            element={
              <Messages
                queuedMessages={queuedMessages}
                pastMessages={pastMessages}
              />
            }
          />
          <Route path="dashboard/sendmessages/*" element={<SendMessages />} />
          <Route
            path="dashboard/templates"
            element={<Templates templates={templates} />}
          />
          <Route path="*" element={<Navigate replace to="/dashboard" />} />
        </Routes>
      )}
    </Layout>
  ) : (
    <div className="login-form">
      <form onSubmit={onLoginHandler}>
        <div className="input-box">
          비밀번호 입력
          <input type="password" name="password" />
          <button className="submit-btn" type="submit">
            확인
          </button>
        </div>
      </form>
    </div>
  )
}

export default App
