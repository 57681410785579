import "./SendMessages.css"
import { useEffect, useState } from "react"
import { Routes, Route, Navigate, Link } from "react-router-dom"
import ParkRocheForm from "../components/ParkRocheForm"
import MindTherapyForm from "../components/MindTherapyForm"
import DallemerReportForm from "../components/DallemerReportForm"
import TakeHotelForm from "../components/TakeHotelForm"
import CoachingForm from "../components/CoachingForm"

const SendMessages = () => {
  const clickButtonTabs = e => {
    e.target.classList.add("clicked")
    const buttons = document.querySelectorAll(".button")
    buttons.forEach(button => {
      if (button !== e.target) {
        button.classList.remove("clicked")
      }
    })
  }

  useEffect(() => {
    // 현재 url이 park, take, mind, dallemer, coaching 중 하나인지 확인
    // 해당하는 button에 clicked class 추가
    const buttons = document.querySelectorAll(".button")
    const curUrl = window.location.href.split("/").pop()
    buttons.forEach(button => {
      const to = button.getAttribute("href").split("/").pop()
      if (to === curUrl) {
        button.classList.add("clicked")
      }
    })
  }, [])

  return (
    <div className="send-message-root">
      <div className="button-tabs">
        <Link onClick={clickButtonTabs} className="button" to="park">
          파크로쉬
        </Link>
        <Link onClick={clickButtonTabs} className="button" to="take">
          테이크 호텔
        </Link>
        <Link onClick={clickButtonTabs} className="button" to="mind">
          마음 달램
        </Link>
        <Link onClick={clickButtonTabs} className="button" to="dallemer">
          달래머 리포트
        </Link>
        <Link onClick={clickButtonTabs} className="button" to="coaching">
          달램 코칭
        </Link>
      </div>
      <div className="form-wrapper">
        <Routes>
          <Route path="park" element={<ParkRocheForm />} />
          <Route path="take" element={<TakeHotelForm />} />
          <Route path="mind" element={<MindTherapyForm />} />
          <Route path="dallemer" element={<DallemerReportForm />} />
          <Route path="coaching" element={<CoachingForm />} />
          <Route path="*" element={<Navigate replace to="park" />} />
        </Routes>
      </div>
    </div>
  )
}

export default SendMessages
