import "./Templates.css"
import { useState } from "react"
import ListSelect from "../components/ListSelect"

function Templates({ templates = [] }) {
  const [curTemplates, setCurTemplates] = useState(templates[0])

  const selectTemplate = template => {
    setCurTemplates(template)
  }

  return (
    <div className="select-template area">
      <ListSelect
        list={templates}
        style={{ "--highlight": "#6f6ee2" }}
        selectTemplate={selectTemplate}
      />
      <div className="preview">
        <div className="name">달램</div>
        <div className="chatbox">
          {curTemplates?.templtContent}
          {curTemplates
            ? curTemplates.buttons.map((button, i) => (
                <a
                  key={i}
                  className="chatbox-button"
                  href={button.linkMo}
                  target="_blank">
                  {button.name}
                </a>
              ))
            : "템플릿을 선택해주세요."}
        </div>
      </div>
    </div>
  )
}

export default Templates
