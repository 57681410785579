import "./Messages.css"
import MessagesList from "../components/MessagesList"

function Messages({ queuedMessages = [], pastMessages = [] }) {
  return (
    <>
      <div className="messages">
        <h2>전송 대기중</h2>
        <MessagesList messages={queuedMessages} />
      </div>
      <div className="messages">
        <h2>전송 완료</h2>
        <MessagesList messages={pastMessages} />
      </div>
    </>
  )
}

export default Messages
