import "./ParkRocheForm.css"
import LoadingIndicator from "./LoadingIndicator"
import { useState } from "react"
import { postMessages } from "../libs/knoti-sdk"
const ParkRocheForm = () => {
  // 필요한 알림톡
  // 예약 확정 후 즉시 "예약 완료" 알림톡" - 고객명, 숙박일정, 예약번호, {객실, 베드 종류}, 조식 포함 여부
  // 일정 하루 전 "하루전 예약 알림 알림톡" -고객명, 숙박일정, 예약번호, 객실, 베드 종류, 조식 포함여부
  // 체크아웃 시간 11시에 "체크아웃 설문조사 알림톡" - 변수 x

  // 기본 정보 : 사용자 전화번호, 고객명
  // 변수 : 숙박일정, 예약번호, 객실 베드 종류, 조식 포함 여부
  // 트리거 - 숙박 일정 포맷 - YY.MM.DD~YY.MM.DD
  // 폼 전송 시 예약 완료 바로 보내기
  // 앞에날짜 -1 해서 오후 3시에 보내기 (입실 하루 전)
  // 뒤에날짜 당일 오전 11시에 보내기 (체크아웃 설문)
  const [loading, setLoading] = useState(false)
  const onSubmitHandler = async e => {
    e.preventDefault()

    const curForm = new FormData(e.currentTarget)
    const formType = curForm.get("formType")?.trim()
    const userName = curForm.get("name")?.trim()
    const userPhone = curForm.get("hp")?.trim()
    const startDate = curForm.get("startDate")?.trim()
    const endDate = curForm.get("endDate")?.trim()
    const bookingNo = curForm.get("bookingNo")?.trim()
    const roomCategory = curForm.get("roomCategory")?.trim()
    const breakfastCount = curForm.get("breakfastCount")?.trim()

    console.log(
      formType,
      userName,
      userPhone,
      startDate,
      endDate,
      bookingNo,
      roomCategory,
      breakfastCount
    )
    const alimtalkVars = {
      formType,
      userName,
      userPhone,
      startDate,
      endDate,
      bookingNo,
      roomCategory,
      breakfastCount
    }
    try {
      setLoading(true)
      await postMessages(alimtalkVars)
      setLoading(false)
      alert("알림톡 보내기 완료")
    } catch (err) {
      setLoading(false)
      alert(`알림톡 보내기 실패\n에러 사유: ${err}`)
    }
  }
  return (
    <>
      <form className="form" action="" onSubmit={onSubmitHandler}>
        <input required type="hidden" name="formType" value="park" />
        <div className="box-title">기본 정보</div>
        <div className="horizontal-box">
          <div className="input-box">
            고객명
            <input required type="text" name="name" />
          </div>
          <div className="input-box">
            전화번호
            <input required type="tel" name="hp" />
          </div>
        </div>

        <div className="box-title">숙박 일정</div>
        <div className="horizontal-box">
          <div className="input-box">
            시작일
            <input required type="date" name="startDate" />
          </div>
          <div className="input-box">
            종료일
            <input required type="date" name="endDate" />
          </div>
        </div>

        <div className="box-title">나머지 정보</div>
        <div className="input-box">
          예약번호
          <input required type="text" name="bookingNo" />
        </div>
        <div className="input-box">
          객실, 베드 종류
          <input required type="text" name="roomCategory" />
        </div>
        <div className="input-box">
          조식 인원 수 (없으면 0으로 입력)
          <input
            required
            type="number"
            name="breakfastCount"
            defaultValue={0}
          />
        </div>
        <button className="submit-btn" type="submit">
          알림톡 전송
        </button>
      </form>
      {loading ? (
        <div>
          알림톡 보내는 중... 잠시만 기다려주세요
          <LoadingIndicator />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default ParkRocheForm
