import axios from "axios"

const { REACT_APP_KAKAO_ALERT_SERVER_ROOT = "http://localhost:5000" } =
  process.env
axios.defaults.baseURL = REACT_APP_KAKAO_ALERT_SERVER_ROOT

export const getMessages = async ({ start, end, detail = true, filter }) => {
  return await axios
    .get("/messages", {
      params: {
        start,
        end,
        detail,
        filter
      },
      withCredentials: true
    })
    .then(res => {
      console.log(res)
      if (res.data.error) {
        throw Error(res.data.error)
      }
      return res.data
    })
}
export const postMessages = async (alimtalkVars = {}) => {
  return await axios
    .post("/alimtalk/send", alimtalkVars, { withCredentials: true })
    .then(res => {
      return res.data
    })
}

export const getTemplates = async () =>
  await axios.get("/templates", { withCredentials: true }).then(res => {
    if (res.data.error) {
      throw Error(res.data.error)
    }
    return res.data
  })

export const deleteMessages = async mid =>
  await axios.delete(`/messages/${mid}`, { withCredentials: true })
