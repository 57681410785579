import MessageList from "../components/MessagesList"

function Dashboard({ messages = [] }) {
  return (
    <div className="dashboard">
      <MessageList messages={messages} />
    </div>
  )
}

export default Dashboard
