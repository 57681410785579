import "./DallemerReportForm.css"
import LoadingIndicator from "./LoadingIndicator"
import { useState } from "react"
import { postMessages } from "../libs/knoti-sdk"

const DallemerReportForm = () => {
  // 달래머 리포트
  // 서비스 N분 전, 서비스 후 리포트 2번 보내야함
  //
  // 서비스 N분 전 - 서비스명, 달래머, 남은시간
  // 서비스 후  -서비스명, 달래머

  // 기본 정보 - 달래머 명, 달래머 전화번호
  // 서비스 정보 - 서비스 명, 남은시간 (분 단위)
  const [loading, setLoading] = useState(false)
  const onSubmitHandler = async e => {
    e.preventDefault()

    const curForm = new FormData(e.currentTarget)
    const formType = curForm.get("formType")?.trim()
    const startDate = curForm.get("startDate")?.trim()
    const endDate = curForm.get("endDate")?.trim()
    const dallemerName = curForm.get("dallemerName")?.trim()
    const dallemerPhone = curForm.get("dallemerPhone")?.trim()
    const serviceName = curForm.get("serviceName")?.trim()

    const alimtalkVars = {
      formType,
      startDate,
      endDate,
      dallemerName,
      dallemerPhone,
      serviceName
    }

    try {
      setLoading(true)
      const { successList, failedList } = await postMessages(alimtalkVars)
      setLoading(false)
      alert(
        `알림톡 보내기 완료\n전송 성공 : ${successList.join(
          ", "
        )}\n전송 실패 : ${failedList.join(", ")}`
      )
    } catch (err) {
      setLoading(false)
      alert(`알림톡 보내기 실패\n에러 사유: ${err}`)
    }
  }

  return (
    <>
      <form className="form" onSubmit={onSubmitHandler}>
        <input required type="hidden" name="formType" value="dallemer" />
        <div className="box-title">기본 정보</div>
        <div className="horizontal-box">
          <div className="input-box">
            달래머명
            <input required type="text" name="dallemerName" />
          </div>
          <div className="input-box">
            달래머 전화번호
            <input required type="tel" name="dallemerPhone" />
          </div>
        </div>
        <div className="box-title">서비스 정보</div>
        <div className="horizontal-box">
          <div className="input-box">
            서비스명
            <input required type="text" name="serviceName" />
          </div>
        </div>
        주의 : 오전 12시 = 0시, 오후 12시 = 12시
        <div className="horizontal-box">
          <div className="input-box">
            시작일
            <input required type="datetime-local" name="startDate" />
          </div>
          <div className="input-box">
            종료일
            <input required type="datetime-local" name="endDate" />
          </div>
        </div>
        <button className="submit-btn" type="submit">
          알림톡 전송
        </button>
      </form>
      {loading ? (
        <div>
          알림톡 보내는 중... 잠시만 기다려주세요
          <LoadingIndicator />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default DallemerReportForm
