import "./LoadingIndicator.css"
function LoadingIndicator() {
  return (
    <div className="loading-indicator">
      <div />
      <div />
      <div />
    </div>
  )
}

export default LoadingIndicator
