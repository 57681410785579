import "./MindTherapyForm.css"
import LoadingIndicator from "./LoadingIndicator"
import { useState } from "react"
import { postMessages } from "../libs/knoti-sdk"

const MindTherapyForm = () => {
  // 마음달램은
  // 고객 하루 전,고객 10분 전, 선생님 1시간전, 선생님 종료
  // 총 알림톡 4번을 보내야됨
  //
  // 고객 하루전 변수 - 서비스명, 고객명, 서비스명, 월일시간
  // 고객 10분전 변수 -서비스명, 고객명, 서비스명, 월일시간
  // 달래머 종료 알림 변수 - 서비스명, 달래머
  // 달래머 1시간전 알림 변수 - 서비스명, 달래머

  // 기본 정보 - 고객명, 고객 전화번호
  //           달래머 명, 달래머 전화번호
  // 서비스 정보 - 서비스 명, 월일 시간 ( M월 M일 HH:DD 형식 or 오후:오전 HH:DD )
  const [loading, setLoading] = useState(false)
  const onSubmitHandler = async e => {
    e.preventDefault()

    const curForm = new FormData(e.currentTarget)
    const formType = curForm.get("formType")?.trim()
    const userName = curForm.get("userName")?.trim()
    const userPhone = curForm.get("userPhone")?.trim()
    const dallemerName = curForm.get("dallemerName")?.trim()
    const dallemerPhone = curForm.get("dallemerPhone")?.trim()
    const serviceName = curForm.get("serviceName")?.trim()
    const startDate = curForm.get("startDate")?.trim()
    console.log(
      formType,
      userName,
      userPhone,
      dallemerName,
      dallemerPhone,
      serviceName,
      startDate
    )

    const alimtalkVars = {
      formType,
      userName,
      userPhone,
      dallemerName,
      dallemerPhone,
      serviceName,
      startDate
    }

    try {
      setLoading(true)
      const { successList, failedList } = await postMessages(alimtalkVars)
      setLoading(false)
      alert(
        `알림톡 보내기 완료\n전송 성공 : ${successList.join(
          ", "
        )}\n전송 실패 : ${failedList.join(", ")}`
      )
    } catch (err) {
      setLoading(false)
      alert(`알림톡 보내기 실패\n에러 사유: ${err}`)
    }
  }

  return (
    <>
      <form className="form" onSubmit={onSubmitHandler}>
        <input required type="hidden" name="formType" value="mind" />

        <div className="box-title">기본 정보</div>
        <div className="horizontal-box">
          <div className="input-box">
            고객명
            <input required type="text" name="userName" />
          </div>
          <div className="input-box">
            고객 전화번호
            <input required type="tel" name="userPhone" />
          </div>
        </div>
        <div className="horizontal-box">
          <div className="input-box">
            달래머명
            <input required type="text" name="dallemerName" />
          </div>
          <div className="input-box">
            달래머 전화번호
            <input required type="tel" name="dallemerPhone" />
          </div>
        </div>

        <div className="box-title">서비스 정보</div>
        <div className="horizontal-box">
          <div className="input-box">
            서비스명
            <input required type="text" name="serviceName" />
          </div>
          <div className="input-box">
            월일시간
            <input required type="datetime-local" name="startDate" />
          </div>
        </div>

        <button className="submit-btn" type="submit">
          알림톡 전송
        </button>
      </form>
      {loading ? (
        <div>
          알림톡 보내는 중... 잠시만 기다려주세요
          <LoadingIndicator />
        </div>
      ) : (
        ""
      )}
    </>
  )
}

export default MindTherapyForm
