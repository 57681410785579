import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import "./Layout.css"

function Layout({ children, queuedMessages, templates }) {
  const msgCnt = queuedMessages.length
  const tmpCnt = templates.length
  const [displaySegment, setDisplaySegment] = useState("대쉬보드")

  const displaySegmentHandler = segmnet => {
    setDisplaySegment(segmnet)
  }

  return (
    <div className="root">
      <div className="sidebar">
        <div className="header">달램 알림톡</div>
        <div className="navigation">
          <Link
            className="link"
            to="/dashboard"
            onClick={displaySegmentHandler.bind(null, "대쉬보드")}>
            <span className="label">대쉬보드</span>
          </Link>
          <Link
            className="link"
            to="/dashboard/messages"
            onClick={displaySegmentHandler.bind(null, "메세지")}>
            <span className="label">메시지</span>
            <span className="badge">{msgCnt}</span>
          </Link>
          <Link
            className="link"
            to="/dashboard/sendmessages"
            onClick={displaySegmentHandler.bind(null, "알림톡 보내기")}>
            <span className="label">알림톡 보내기</span>
          </Link>
          <Link
            className="link"
            to="/dashboard/templates"
            onClick={displaySegmentHandler.bind(null, "템플릿")}>
            <span className="label">템플릿</span>
            <span className="badge">{tmpCnt}</span>
          </Link>
        </div>
      </div>
      <main>
        <div className="container">
          <h1>{displaySegment}</h1>

          {children}
        </div>
      </main>
    </div>
  )
}

export default Layout
