import "./Modal.css"
import { useEffect, useState, forwardRef, useImperativeHandle } from "react"
import Centerbox from "./Centerbox"

const Modal = forwardRef(({ maxwidth = 1200, children }, ref) => {
  const [show, setShow] = useState(false)

  useImperativeHandle(ref, () => ({
    modalOpen
  }))

  function modalOpen() {
    setShow(true)
  }
  function modalClose() {
    setShow(false)
  }

  useEffect(() => {
    window.addEventListener("keydown", event => {
      if (event.key === "Escape" && show) {
        modalClose()
      }
    })
  }, [])
  return show ? (
    <div className="modal-wrapper" onClick={modalClose}>
      <Centerbox style={{ overflowY: "scroll" }}>
        <div
          className="modal"
          style={{ maxWidth: `${maxwidth}px` }}
          onClick={event => {
            event.stopPropagation()
          }}>
          {children}
        </div>
      </Centerbox>
    </div>
  ) : (
    ""
  )
})

export default Modal
