import { useRef, useState } from "react"
import { deleteMessages } from "../libs/knoti-sdk"
import "./MessagesList.css"
import Modal from "./Modal"
import TeenyIcons from "./TeenyIcons"

function MessageList({ messages = [] }) {
  const [curMessage, setCurMessage] = useState(messages[0])
  const modalRef = useRef({})

  const showMessageContent = message => {
    setCurMessage(message)
    modalRef.current.modalOpen()
  }

  return (
    <>
      <div className="messages-list">
        <table>
          <thead>
            <tr>
              <th>전송시간</th>
              <th>전화번호</th>
              <th>상태</th>
              <th>이름</th>
              <th>템플릿 종류</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {messages.map((message, i) => {
              const detail = message.list[0] ?? {}
              const userName = detail?.message
                ?.match(/(?<=\n)(.*?)(?=님)/gsu)
                ?.toString()
                ?.replace(/\n/, "")
              const alimType = detail?.message
                ?.match(/.*/)
                ?.toString()
                ?.replace(/♡/g, "")
              return (
                <tr
                  className={detail.rslt != 0 ? "message error" : "message"}
                  id={`msg-${detail.msgid}`}
                  key={i}>
                  <td className="sent_date">
                    {detail?.msgid?.startsWith("Q")
                      ? detail?.reqdate
                      : detail?.sentdate}
                  </td>
                  <td className="phone">{detail.phone}</td>
                  <td className="result">{detail.rslt_message}</td>
                  <td className="user_name">{userName}</td>
                  <td className="alim_type">{alimType}</td>
                  <td className="actions">
                    <div
                      className="button"
                      onClick={() => {
                        showMessageContent(message)
                      }}>
                      <TeenyIcons
                        icon="message"
                        style={{ width: "20px", height: "20px" }}
                        variant="outline"
                      />
                      <span className="label">내용 보기</span>
                    </div>
                    <div
                      className="button"
                      onClick={async () => {
                        if (confirm("전송을 취소할까요?")) {
                          const res = await deleteMessages(message.mid)
                          alert("전송 목록에서 삭제했습니다!")
                        }
                      }}>
                      <TeenyIcons
                        icon="x"
                        style={{ width: "20px", height: "20px" }}
                        variant="outline"
                      />
                      <span className="label">전송 취소</span>
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <Modal maxwidth={400} ref={modalRef}>
        {curMessage ? (
          <div className="preview">
            <div className="name">달램</div>
            <div className="chatbox">
              {curMessage?.list?.[0]?.message}
              {curMessage?.list?.[0]?.button_json &&
              JSON.parse(curMessage?.list?.[0]?.button_json)
                ? JSON.parse(curMessage?.list?.[0]?.button_json)?.button.map(
                    (button, i) => (
                      <a
                        key={i}
                        className="chatbox-button"
                        href={button.linkMo}
                        target="_blank">
                        {button.name}
                      </a>
                    )
                  )
                : ""}
            </div>
          </div>
        ) : (
          ""
        )}
      </Modal>
    </>
  )
}

export default MessageList
