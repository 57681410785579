import axios from "axios"

export const loginWithPassword = async password => {
  return await axios
    .post(
      "/users/login",
      {
        password
      },
      {
        headers: {
          "Content-Type": "application/json"
        },
        withCredentials: true
      }
    )
    .then(res => {
      return res.data
    })
}
