import { useState } from "react"
import "./ListSelect.css"
function ListSelect({
  list = [],
  selectedIndexInit = 0,
  selectedIndices = [],
  variant = "vertical",
  spaced = false,
  multiselect = false,
  limitHeight = 0,
  style = {},
  selectTemplate
}) {
  const [selectedIndex, setSelectedIndex] = useState(selectedIndexInit)

  const isVertical = variant === "vertical" ? "vertical" : ""
  const isHorizontal = variant === "horizontal" ? "horizontal" : ""
  const isSpaced = spaced ? "spaced" : ""
  const isLimited = !!limitHeight ? "limited" : ""
  const isActive = i =>
    multiselect
      ? selectedIndices.indexOf(i) > -1
        ? "active"
        : ""
      : selectedIndex === i
      ? "active"
      : ""

  function handleSelect(template) {
    selectTemplate(template)
  }

  return (
    <div
      className={`t-root ${isVertical} ${isHorizontal} ${isSpaced} ${isLimited}`}
      style={
        !!limitHeight ? { maxHeight: `${limitHeight}px`, ...style } : style
      }>
      {list.map((item, i) => {
        return (
          <div
            key={i}
            className="item"
            onClick={() => {
              if (multiselect) {
                if (selectedIndices.indexOf(i) < 0) {
                  selectedIndices = [...selectedIndices, i]
                } else {
                  const idx = selectedIndices.indexOf(i)
                  selectedIndices = [
                    ...selectedIndices.slice(0, idx),
                    ...selectedIndices.slice(idx + 1)
                  ]
                }
                handleSelect(selectedIndices.map(index => list[index]))
              } else {
                // selectedIndex = i
                setSelectedIndex(i)
                handleSelect(list[selectedIndex])
              }
            }}>
            <div className={`selection ${isActive(i)}`} />
            {item.templtName}
          </div>
        )
      })}
    </div>
  )
}

export default ListSelect
